import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"


export const ordersApi = createAsyncThunk(
    'auth/orders',
    async (data, { rejectWithValue }) => {
        try {
            //dealor/filteroutletbydealergroup/
            const response = await AuthAxios.get(`/api/v1/supplier/get_all_orders_supplier/`,{ 
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") } 
            });
            
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const ordersSlice = createSlice({
    name: 'orders',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(ordersApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(ordersApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.data
            state.message = action?.payload?.message
        })
        builder.addCase(ordersApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const ordersReducer = ordersSlice.reducer