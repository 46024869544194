import thunk from "redux-thunk";
import logger from 'redux-logger'
import rootReducer from "./combineReducer";
import storage from 'redux-persist/lib/storage'; 
import persistStore from "redux-persist/es/persistStore";
import persistReducer from "redux-persist/es/persistReducer";
import { configureStore } from "@reduxjs/toolkit";

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    applyMiddleware: ([thunk, logger])
})

export const persistor = persistStore(store);