export const handleDownload = (fileName , url) => {
    const link = document.createElement('a');
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const fileUrl = baseUrl+`${url}`;
    link.href = fileUrl;
    link.download = fileName;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};