import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button, Card, IconButton, Modal, Stack, Typography, Box } from '@mui/material';
import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import ResetPassword from './ResetPassword';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { verifyAuthOtpApi } from '../../store/auth/verifyAuthOtp';
import {authOtpViaEmailApi} from "../../store/auth/authOtpViaEmail"
import ModalCss from "../../styles/modal.module.scss";
import { showToast } from '../../toast/toast';
export default function ResetViaEmailOTP({ open, setOpen }) {
  const dispatch = useDispatch()
  const [otp, setOtp] = useState('')
  const [resetPasswordModal, setResetPasswordModal] = useState(false)
  const forgetPasswordData = useSelector(state => state.forgetPasswordViaEmail);

  const access_token = forgetPasswordData?.result?.access_token ? forgetPasswordData?.result?.access_token  : localStorage.getItem("access_token")
  const dealorEmail = forgetPasswordData?.result?.email ? forgetPasswordData?.result?.email  : localStorage.getItem("email")
  const handleClose = () => setOpen(false)
  const loginState = useSelector(state => state.login);
  const user_type =   "Supplier";

  const handleVerify = async () => {
    const verifyData = await dispatch(verifyAuthOtpApi({ otp: Number(otp),user_type, type: "email", email: dealorEmail }, { headers: { Authorization: `Bearer ${access_token}` } }))
    if (!verifyData?.payload?.error) {
      localStorage.setItem("access_token", verifyData?.payload?.result?.access_token)
      setResetPasswordModal(true)
      handleClose()
    }else{
      showToast(verifyData?.payload?.message, 2)
    }
  }
  const handleResendOtp = () => {
    dispatch(authOtpViaEmailApi({ type: "email", user_type: user_type, email:dealorEmail }))
  }

  return (
    <>
      <Modal open={open} onClose={handleClose} className={ModalCss?.customModal}>
        <Card className={`modal ${ModalCss?.customModal_md}`}>
          <Stack spacing={2} p={3} position={"relative"}>
            <Stack alignItems="flex-end"
              position={"absolute"}
              right={5}
              top={5}><IconButton onClick={handleClose}><HighlightOffIcon /></IconButton></Stack>
            <Stack alignItems='center'>
              <Typography variant='h5' fontWeight={600}>Email Verification</Typography>
              <Typography>{`We have sent code to your Email ${dealorEmail}`}</Typography>
            </Stack>
            <OtpInput
              containerStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBlock: "50px 30px" }}
              inputStyle={{ width: 72, height: 72, fontSize: "20px", border: "2px solid black", borderRadius: "10px"  }}
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderSeparator={<span>&nbsp; &nbsp;</span>}
              renderInput={(props) => <input {...props} />}
            />
             <Box sx={{ textAlign: "center" }}>
                <Button  sx={{
                  marginTop: "20px !important",
                  width: "80%",
                  padding: { lg: "20px 10px", xs: "10px" },
                  fontSize: { lg: "18px", xs: "14px" },
                  fontWeight: { lg: "500", xs: "500" },
                  borderRadius: "10px",
                }} variant='contained' onClick={handleVerify}>Verify Account</Button>
             </Box>
            <Stack justifyContent='center' direction='row' alignItems='center' fontSize={'18px'} marginTop={'30px'}>
              <Typography fontSize={'inherit'}>Don’t receive code?</Typography>
              <Button sx={{fontSize: "16px", fontWeight: "600"}} onClick={()=>handleResendOtp()}>Resend</Button>
            </Stack>
          </Stack>
        </Card>
      </Modal>

      <ResetPassword open={resetPasswordModal} setOpen={setResetPasswordModal} />
    </>
  )
}
