import { toast } from "react-hot-toast";
import axios from "axios";


const instance = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_BASE_URL,
});


instance.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    return Promise.reject(error);
});


instance.interceptors.response.use(function (response) {
    if (response?.config?.method != "get") {
        //toast.success(response?.data?.message, { id: 'toast', duration: 5000, position: 'top-center' })
    }
    return response;
}, function (error) {
     //toast.error(error?.response?.data?.message, { id: 'toast', duration: 5000, position: 'top-center' })
    return Promise.reject(error);
});

export default instance