import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ForgetPassword from "../view/auth/ForgetPassword";
import Login from "../view/auth/Login";
import Home from "../view/home";
import OrderStatus from "../view/orders/OrderStatus";
import UpdateDispatchStatus from "../view/orders/UpdateDispatchStatus";
import ProtectedRoute from "./ProtectedRoute";
import { currentSupplierApi } from "../store/auth/currentSupplier";
import InventoryManagement from "../view/inventory/InventoryManagement";
import NotRoute from "../view/404Route";
import ProtectedLayout from "../components/ProtectedLayout";
import ViewOrder from "../view/orders/ViewOrder";
import DispatchStatus from "../view/home/DispatchStatus";


export default function MainRoutes() {

    // const currentSupplierSelector = useSelector(state => state?.currentSupplier)
    // const { result: { first_name } } = currentSupplierSelector


    // const dispatch = useDispatch()

    // const token = JSON.parse(localStorage.getItem("token"))

    // useEffect(() => {
    //     console.log(token)
    //     if(token!= null){
    //         dispatch(currentSupplierApi(token))
    //     }
        
    // }, [token])


    return (
        <BrowserRouter>
            <Routes>
              <Route path="/" element={<ProtectedLayout />}>
                <Route
                  path="/"
                  element={
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/orders/view"
                  element={
                    <ProtectedRoute>
                      <OrderStatus />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/orders/dispatch/details"
                  element={
                    <ProtectedRoute>
                      <UpdateDispatchStatus />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/inventory"
                  element={
                    <ProtectedRoute>
                      <InventoryManagement />
                    </ProtectedRoute>
                  }
                />
                <Route 
                  path="/order"
                  element={
                  <ProtectedRoute>
                    <ViewOrder/>
                  </ProtectedRoute>}
                  />
              </Route>
              <Route path="/login" element={<Login />} />
              <Route path="/forget-password" element={<ForgetPassword />} />
            </Routes>
        </BrowserRouter>
    )
}