import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"

export const changeOrderStatusApi = createAsyncThunk(
    'orders/change-order-status',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.patch(`api/v1/supplier/order_manage`,
                data,
                { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") } })
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const changeOrderStatusSlice = createSlice({
    name: 'change-order-status',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(changeOrderStatusApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(changeOrderStatusApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(changeOrderStatusApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const changeOrderStatusReducer = changeOrderStatusSlice.reducer