import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"

export const verifyAuthOtpSMSApi = createAsyncThunk(
    'auth/verify-auth-otp',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post(`/api/v1/login/verify_otp/`, {
                user_type: "Supplier",
                type: 'mobile',
                otp: data?.otp
            },
                { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") } }
            )
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const verifyAuthOtpSMSSlice = createSlice({
    name: 'verify-auth-otp',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(verifyAuthOtpSMSApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(verifyAuthOtpSMSApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(verifyAuthOtpSMSApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const verifyAuthOtpSMSReducer = verifyAuthOtpSMSSlice.reducer