import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InventoryIcon from "@mui/icons-material/Inventory";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LogoWhite from "../assets/img/kialogowhite.png";
import Style from "../styles/drawer.module.scss";
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import { title } from 'process';

import SpeedIcon from "@mui/icons-material/Speed";
import GroupIcon from "@mui/icons-material/Group";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";

import EngineeringIcon from "@mui/icons-material/Engineering";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import PeopleIcon from "@mui/icons-material/People";
import CopyrightIcon from "@mui/icons-material/Copyright";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { BorderClear, Padding } from "@mui/icons-material";

import AccountCircleIcon from '@mui/icons-material/AccountCircle';


export default function Drawer() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [expandedAccordion, setExpandedAccordion] = useState(null);

  const expandIconStyle = {
    ml: 7,
  };

  useEffect(() => {
    // Split the pathname into segments
    const segments = pathname.split("/").filter(Boolean);


    // Get the first segment of the URL path
    const firstSegment = segments[0];

    // Determine which accordion to expand based on the first segment
    if (firstSegment === "order") {
      setExpandedAccordion("orderManagementAccordion");
    } else if (firstSegment === "inventory") {
      setExpandedAccordion("inventoryManagementAccordion");
    } else if (
      firstSegment === "users" ||
      firstSegment === "kin" ||
      firstSegment === "innoceans"
    ) {
      setExpandedAccordion("userManagementAccordion");
    }
  }, [pathname]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null);
  };

  return (
    <>
      <Box className={Style.open}>
        <Box className={Style?.logo} onClick={() => navigate("/")}>
          <img src={LogoWhite} alt="logo" />
        </Box>
        <List sx={{ width: "100%", fontSize: "15px", fontWeight: "400" }}>
             <ListItem
            className={
              pathname === "/"
                ? Style.activeLink
                : Style?.inActiveLink
            }
            disablePadding
          >
            <ListItemButton
              sx={{ borderBottom: "1px solid rgba(42, 58, 70, 1)" }}
              component={Link}
              to="/"
            >
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText
                primary="Home"
                sx={{
                  ml: -1,
                  "& .MuiTypography-root": {
                    fontSize: "14px",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem
            sx={{ p: 0 }}
            className={
              pathname.includes("/v") ? Style.activeLink : Style?.inActiveLink
            }
          >
          </ListItem>
          <ListItem
            sx={{ p: 0 }}
            className={
              pathname.includes("/v") ? Style.activeLink : Style?.inActiveLink
            }
          >
            <Accordion
              expanded={expandedAccordion === "orderManagementAccordion"}
              onChange={handleAccordionChange("orderManagementAccordion")}
              sx={{ flexGrow: "1", width: "100%" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  pl: 2,
                  pr: 2,
                  borderBottom: "1px solid rgba(42, 58, 70, 1)",
                }}
                aria-controls="orderManagementAccordion-content"
                id="orderManagementAccordion-header"
              >
                <WidgetsOutlinedIcon sx={{ mr: 1.5 }}/>
                <Typography
                  sx={{
                    ml: 1,
                    fontSize: "14px",
                  }}
                >
                  Order Management
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  width: "100%",
                  boxSizing: "border-box",
                  backgroundColor: "rgba(8, 27, 41, 1)",
                  p: 0,
                  px: 4,
                }}
              >
                <ul className={Style.menuUnstyledList}>
                  <li>
                    <Link className="linkLight" to="/order">
                      View order
                    </Link>
                  </li>
                  {/* <li>
                    <Link className="linkLight" to="/order/dispatch-status">
                      Update dispatch status
                    </Link>
                  </li> */}
                </ul>
              </AccordionDetails>
            </Accordion>
          </ListItem>

          <ListItem
            sx={{ p: 0 }}
            className={
              pathname === "/inventory"
                ? Style.activeLink
                : Style?.inActiveLink
            }
            onClick={() => navigate("/inventory")}
          >
            <Accordion
              expanded={expandedAccordion === "inventoryManagementAccordion"}
              onChange={handleAccordionChange("inventoryManagementAccordion")}
              sx={{ flexGrow: "1", width: "100%" }}
            >
              <AccordionSummary
               sx={{
                  pl: 2,
                  pr: 2,
                  borderBottom: "1px solid rgba(42, 58, 70, 1)",
                }}
                aria-controls="inventoryManagementAccordion-content"
                id="inventoryManagementAccordion-header"
                
              >
              <InventoryOutlinedIcon sx={{ mr: 1.5 }}/>
                <Typography
                  sx={{
                    ml: 1,
                    fontSize: "14px",
                  }}
                  
                >
                  Inventory Management
                </Typography>
              </AccordionSummary>
              
            </Accordion>
          </ListItem>

     
        </List>
      </Box>
    </>
  );
}
