import { Alert } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'



export default function SnakBar({ type, error }) {
    return (
        <Alert severity={type}>{error}</Alert>
    )
}


SnakBar.propTypes = {
    type: PropTypes.any,
    error: PropTypes.any,
};