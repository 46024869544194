export const  isAllOrderValidationTrue = (orderItems) => {
    // Iterate through each item in the orderItems array
    for (let item of orderItems) {
      // Check if the order_validation property is false
      if (!item.order_validation) {
        // If any order_validation is false, return false
        return false;
      }
    }
    // If all order_validation are true, return true
    return true;
}


export const  isAllOrderAcceptedTrue = (orderItems) => {
  // Iterate through each item in the orderItems array
  for (let item of orderItems) {
    // Check if the order_validation property is false
    if (!item.order_accepted) {
      // If any order_validation is false, return false
      return false;
    }
  }
  // If all order_validation are true, return true
  return true;
}


export const calculateTotal = (orderItems) => {
  const total = orderItems.reduce((total, item) => {
    if (item.quantity && item.price) {
      return total + (item.quantity * item.price);
    }
    return total;
  }, 0);

  return total.toLocaleString(); // Format the total with commas
};

export const calculateTotalByItem = (quantity, price) => {
  if (quantity && price) {
    return (quantity * price).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return '0.00';
};

