import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box, Button, Card, IconButton, Modal, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authOtpViaEmailApi } from '../../store/auth/authOtpViaEmail';
import { currentSupplierApi } from '../../store/auth/currentSupplier';
import BackdropUI from '../../ui/Backdrop';
import SupplierStatusConf from './SupplierStatusConf';
import { useForm } from 'react-hook-form';
import SnakBar from '../../common/SnakBar';
import PropTypes from 'prop-types'
import { MdOutlineKeyboardBackspace } from 'react-icons/md'
import ModalCss from "../../styles/modal.module.scss";
import { showToast } from '../../toast/toast';
import { verifyAuthOtpEmailApi } from '../../store/auth/verifyAuthOtpEmail';



export default function OTPEmailAuth({ open, setOpen, handleSendLink }) {

  const { setError, formState: { errors }, } = useForm()

  const authOtpViaEmailSelector = useSelector(state => state?.authOtpViaEmail)
  const { loading } = authOtpViaEmailSelector

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [otp, setOtp] = useState('')
  const [backdrop, setBackDrop] = useState(false)
  const [dealorStatus, setDealorStatus] = useState(false)
  const loginState = useSelector((state) => state.login);
  const user_type = 'Supplier'
  const dealorEmail = loginState?.result?.email ? loginState?.result?.email : localStorage.getItem("email");

  const handleClose = () => {
    setOpen(false)
    setOtp([])
  }
  const handleBack = () => {
    setOpen(false)
    setOtp([])
  }

  const handleVerify = async () => {
    const verifyAuthOTP = await dispatch(
      verifyAuthOtpEmailApi({ otp: Number(otp), user_type, type: "email" , email:loginState?.result?.email})
    );

    if (!verifyAuthOTP?.payload?.error) {
      localStorage.setItem(
        "access_token",
        verifyAuthOTP?.payload?.result?.access_token
      );
      
      navigate("/");
    }else{
      showToast(verifyAuthOTP?.payload?.message, 2)
    }


    // if (!verifyAuthOTP?.payload?.error) {
    //   localStorage.setItem(
    //     "access_token",
    //     verifyAuthOTP?.payload?.result?.access_token
    //   );
    //   if(loginState?.result?.is_dealer === true){
    //     const currentDealorData = await dispatch(currentDealorApi());
    //     if (currentDealorData?.payload?.result?.dealer_status == "none") {
    //       setDealorStatus(true);
    //     }
    //   }
      
    //   navigate("/");
    // }
  };

  const handleResendOtp = () => {
    dispatch(authOtpViaEmailApi())
  }


  useEffect(() => {
    if (loading) setBackDrop(true)
    else setBackDrop(false)
  }, [backdrop, loading])


  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className={ModalCss?.customModal}
      >
        <Card className={`modal ${ModalCss?.customModal_md}`}>
          <Stack spacing={2} p={3} position={"relative"}>
            <Stack 
              alignItems="flex-end"
              position={"absolute"}
              right={5}
              top={5}><IconButton onClick={handleClose}><HighlightOffIcon /></IconButton></Stack>
            <Stack alignItems="center">
              <Typography
                className={ModalCss?.title}
                variant="h5"
                fontWeight={600}
              >
                Email Verification
              </Typography>
              <Typography
                className={ModalCss?.subtitle}
                 
              >{`We have sent code to your Email ${dealorEmail}`}</Typography>
            </Stack>
            <OtpInput
              containerStyle={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBlock: "50px 30px"
              }}
              inputStyle={{ width: 72, height: 72, fontSize: "20px", border: "2px solid black", borderRadius: "10px" }}
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderSeparator={<span>&nbsp; &nbsp;</span>}
              renderInput={(props) => <input {...props} />}
            />
            <Box sx={{ textAlign: "center" }}>
              <Button
                sx={{
                  marginTop: "20px !important",
                  width: "80%",
                  padding: { lg: "20px 10px", xs: "10px" },
                  fontSize: { lg: "18px", xs: "14px" },
                  fontWeight: { lg: "500", xs: "500" },
                  borderRadius: "10px",
                }}
                variant="contained"
                onClick={handleVerify}
                disabled={otp?.length < 4}
              >
                Verify Account
              </Button>
            </Box>
            <Stack justifyContent="center" direction="row" alignItems="center" fontSize={'18px'} marginTop={'30px'}>
              <Typography fontSize={'inherit'}>Don’t receive code?</Typography>
              {/* <Button onClick={() => handleResendOtp()}>Resend</Button> */}
              <Button sx={{fontSize: "16px", fontWeight: "600"}} onClick={() => handleVerify()}>Resend</Button>
            </Stack>
          </Stack>
        </Card>
      </Modal>
      <SupplierStatusConf open={dealorStatus} setOpen={setDealorStatus} />
      <BackdropUI open={backdrop} />
    </>
  )
}


OTPEmailAuth.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.any,
  back: PropTypes.bool,
};