import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import floormap from "../../assets/svg/floormap.svg";
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Collapse from '@mui/material/Collapse';
import tiles from "../../assets/img/tiles.png";
import tiles2 from "../../assets/img/tiles2.png";
import { formatDateString } from '../../utils/dateFormater';
import { calculateTotal, calculateTotalByItem } from '../../utils/orderItemArrayManipulation';
import ViewGFCModal from '../reuseable/ViewGFCModal';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));


const OrderList = ({item}) => {
    const [expanded, setExpanded] = useState(false);
    const [open, setOpen] = useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const [gfcDataArray, setGfcDataArray] = useState([]);
    const openViewGFCModal = (gfcData) => {
        console.log(gfcData);
        setOpen(true);
        setGfcDataArray(gfcData)
    }
    const clostTheModal = () => {
        setGfcDataArray([])
        setOpen(false)
        
    }

  return (
    <>
        
        <Card className="order-card" sx={{marginTop:"20px !important"}}>
                        <CardHeader
                            avatar={
                                <Box sx={{ borderRight: "1px solid #D4E0EC !important",paddingRight: "10px"}}>
                                    <Typography variant="h5" className="fs-15 fw-400">{item?.ordered_by_user_name}</Typography>
                                    <Typography varient="h5" className="fs-15 fw-400">{item?.cluster_name}</Typography>
                                </Box>
                            }
                            action={
                                <Box>
                                    <Button className="black-btn" sx={{padding:"10px 4px 10px 4px !important",minWidth:"110px !important",fontSize:"13px !important",gap:"5px",textTransform:"capitalize"}}>
                                        Create Quotation
                                    </Button>
                                    {/* <Link className="" style={{margin:"auto 23px auto 23px !important",minWidth:"110px !important",fontSize:"13px !important",gap:"5px",textTransform:"capitalize"}}>
                                        Update
                                    </Link>
                                    <Button className="black-btn" sx={{padding:"10px 4px 10px 4px !important",minWidth:"110px !important",fontSize:"13px !important",gap:"5px",textTransform:"capitalize"}}>
                                        Download
                                    </Button> */}
                                </Box>
                            }
                            title="Order ID"
                            subheader={item?.sub_order_id}
                        />
                        <CardContent>
                            <Paper sx={{ width: '100%', overflow: 'hidden',boxShadow:"none !important" }}>
                                <TableContainer className="solidtable" sx={{ maxHeight: 440 }}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableBody>
                              
                                            <TableRow >                                           
                                                <TableCell className="cstm-tablecell1">
                                                    Order Date
                                                </TableCell>
                                                <TableCell className="cstm-tablecell2">
                                                    { item?.order_date ? formatDateString(item?.order_date) : 'NA'}
                                                </TableCell>
                                                <TableCell className="cstm-tablecell1">
                                                    Order Total
                                                </TableCell>
                                                <TableCell className="cstm-tablecell2">
                                                    ₹ { item?.quantity ? calculateTotalByItem(item?.quantity, item?.price) : 'NA'} ({item?.quantity} Materials)
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>                                           
                                                <TableCell className="cstm-tablecell1">
                                                    Order Finalized Date
                                                </TableCell>
                                                <TableCell className="cstm-tablecell2">
                                                    NA
                                                </TableCell>
                                                <TableCell className="cstm-tablecell1">
                                                    Payment on
                                                </TableCell>
                                                <TableCell className="cstm-tablecell2">
                                                    NA
                                                </TableCell>
                                            </TableRow>
                                            {/* <TableRow >                                           
                                                <TableCell className="cstm-tablecell1">
                                                    Transportation Number
                                                </TableCell>
                                                <TableCell className="cstm-tablecell2">
                                                    -
                                                </TableCell>
                                                <TableCell className="cstm-tablecell1">
                                                    Expected Delivery Date
                                                </TableCell>
                                                <TableCell className="cstm-tablecell2">
                                                    -
                                                </TableCell>
                                            </TableRow> */}
                                            {/* <TableRow >                                           
                                                <TableCell className="cstm-tablecell1">
                                                    Delivery Contact Number
                                                </TableCell>
                                                <TableCell className="cstm-tablecell2">
                                                    -
                                                </TableCell>
                                                <TableCell className="cstm-tablecell1">
                                                    Delivery Contact Name
                                                </TableCell>
                                                <TableCell className="cstm-tablecell2">
                                                    -
                                                </TableCell>
                                            </TableRow> */}
                                    </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </CardContent>
                        <CardActions disableSpacing>
                            <Button disabled={item?.gfc_data ===null} onClick={() => openViewGFCModal(item?.gfc_data)} className="white-btn" sx={{padding:"10px 4px 10px 4px !important",minWidth:"110px !important",fontSize:"13px !important",gap:"5px",textTransform:"capitalize"}}>
                            <img src={floormap} /> View GFC
                            </Button>
                            {/*<Button className="fs-12 btn-no-property" sx={{marginLeft:"auto",textTransform:"capitalize",background:"none !important",color:"#000000 !important"}}     
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}>
                                Show more
                            <ExpandMore
                            className="fs-12"
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                            >
                            <ExpandLessIcon className="" sx={{color:"#000000"}} />
                            </ExpandMore>
                            </Button>
                             <ExpandMore
                            className="fs-12"
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                            >
                                Show more
                            <ArrowForwardIosIcon className="fs-14" />
                            </ExpandMore> */}
                        </CardActions>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <CardContent>
                                <Grid container sx={{borderBottom:"3px solid #E6F1FC",paddingBlock:"15px !important"}}>
                                    <Grid item xs={12} sm={12} md={3} lg={3}>
                                        <Box>
                                            <img style={{width:"90%"}} src={tiles} />
                                            <Box className="d-flex">
                                                <Typography variant="h5" className="fs-14">Order ID: &nbsp;</Typography>
                                                <Typography variant="h5" className="fs-14 fw-600" sx={{color:"#157DC9 !important"}}>SD1234</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={9} lg={9}>
                                        <Box className="d-flex w-100">
                                            <Box className="w-50">     
                                                <Box className="d-flex w-100">                        
                                                    <Box className="cstm-tablecell1 w-50" sx={{borderBottom:"1px solid #D3DCE6 !important "}}>
                                                        Order Status
                                                    </Box>
                                                    <Box className="cstm-tablecell2 w-50" sx={{borderBottom:"1px solid #D3DCE6 !important "}}>
                                                        -
                                                    </Box>
                                                </Box>     
                                                <Box className="d-flex w-100">                            
                                                    <Box className="cstm-tablecell1 w-50">
                                                        Manufacturer/Vendor
                                                    </Box>
                                                    <Box className="cstm-tablecell2 w-50">
                                                        Tiles  |  HR Johnson
                                                    </Box>
                                                </Box>                                 
                                            </Box>
                                            <Box className="w-50">     
                                                <Box className="d-flex w-100">                        
                                                    <Box className="cstm-tablecell1 w-50" sx={{borderBottom:"1px solid #D3DCE6 !important "}}>
                                                        Qty
                                                    </Box>
                                                    <Box className="cstm-tablecell2 w-50 no-border" sx={{borderBottom:"1px solid #D3DCE6 !important "}}>
                                                        2000 sq.ft/5000
                                                    </Box>
                                                </Box>     
                                                <Box className="d-flex w-100">                            
                                                    <Box className="cstm-tablecell1 w-50">
                                                        ETA
                                                    </Box>
                                                    <Box className="cstm-tablecell2 w-50 no-border">
                                                        -
                                                    </Box>
                                                </Box>                                 
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{borderBottom:"3px solid #E6F1FC",paddingBlock:"15px !important"}}>
                                    <Grid item xs={12} sm={12} md={3} lg={3}>
                                        <Box>
                                            <img style={{width:"90%"}} src={tiles2} />
                                            <Box className="d-flex">
                                                <Typography variant="h5" className="fs-14">Order ID: &nbsp;</Typography>
                                                <Typography variant="h5" className="fs-14 fw-600" sx={{color:"#157DC9 !important"}}>SD1234</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={9} lg={9}>
                                        <Box className="d-flex w-100">
                                            <Box className="w-50">     
                                                <Box className="d-flex w-100">                        
                                                    <Box className="cstm-tablecell1 w-50" sx={{borderBottom:"1px solid #D3DCE6 !important "}}>
                                                        Order Status
                                                    </Box>
                                                    <Box className="cstm-tablecell2 w-50" sx={{borderBottom:"1px solid #D3DCE6 !important "}}>
                                                        -
                                                    </Box>
                                                </Box>     
                                                <Box className="d-flex w-100">                            
                                                    <Box className="cstm-tablecell1 w-50">
                                                        Manufacturer/Vendor
                                                    </Box>
                                                    <Box className="cstm-tablecell2 w-50">
                                                        Tiles  |  HR Johnson
                                                    </Box>
                                                </Box>                                 
                                            </Box>
                                            <Box className="w-50">     
                                                <Box className="d-flex w-100">                        
                                                    <Box className="cstm-tablecell1 w-50" sx={{borderBottom:"1px solid #D3DCE6 !important "}}>
                                                        Qty
                                                    </Box>
                                                    <Box className="cstm-tablecell2 w-50 no-border" sx={{borderBottom:"1px solid #D3DCE6 !important "}}>
                                                        2000 sq.ft/5000
                                                    </Box>
                                                </Box>     
                                                <Box className="d-flex w-100">                            
                                                    <Box className="cstm-tablecell1 w-50">
                                                        ETA
                                                    </Box>
                                                    <Box className="cstm-tablecell2 w-50 no-border">
                                                        -
                                                    </Box>
                                                </Box>                                 
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Collapse>
                        </Card>
                        <ViewGFCModal open={open} handleClose={clostTheModal} data={gfcDataArray} />
    </>
  )
}

export default OrderList