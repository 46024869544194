import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthAxios from '../../config/authAxios'; 

export const addInventoryApi = createAsyncThunk(
    'inventory/add', 
    async (formData, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post('/api/v1/prime_vendor/innmaterial/', formData, {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },           
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

export const getInventoryApi = createAsyncThunk(
    'inventory/get', 
    async (_, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get('/api/v1/supplier/get_all_products_supplier/', {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },           
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

export const editInventoryApi = createAsyncThunk(
    'inventory/edit', 
    async (formData, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.patch('/api/v1/prime_vendor/innmaterial/', formData, {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },           
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

export const deleteInventoryApi = createAsyncThunk(
    'inventory/delete',
    async (idToDelete, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.delete(`/api/v1/prime_vendor/innmaterial/`,{
                    headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },
                    data:  idToDelete,
                }
                
            )
            return response?.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);


const inventorySlice = createSlice({
    name: 'add',
    initialState: { result: [], loading: false, error: null, message: "" },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(addInventoryApi.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addInventoryApi.fulfilled, (state, action) => {
            state.loading = false;
            state.roleData = action.payload;
            state.error = null;
        });
        builder.addCase(addInventoryApi.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(editInventoryApi.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(editInventoryApi.fulfilled, (state, action) => {
            state.loading = false;
            state.roleData = action.payload;
            state.error = null;
        });
        builder.addCase(editInventoryApi.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(getInventoryApi.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getInventoryApi.fulfilled, (state, action) => {
            state.loading = false;
            state.result = action.payload;
            state.error = null;
        });
        builder.addCase(getInventoryApi.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // builder.addCase(deleteInventoryApi.pending, (state) => {
        //     state.loading = true;
        // });
        // builder.addCase(deleteInventoryApi.fulfilled, (state, action) => {
        //     state.loading = false;
        //     state.result = action.payload;
        //     state.error = null;
        // });
        // builder.addCase(deleteInventoryApi.rejected, (state, action) => {
        //     state.loading = false;
        //     state.error = action.payload;
        // });

    }
});

export const inventoryReducer = inventorySlice.reducer;