import React, { useEffect, useState } from "react";
import Appbar from "../../components/Appbar";
import Drawer from "../../components/Drawer";
import Body from "../../components/Body";
import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Stack } from "@mui/material";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';

import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link } from "react-router-dom";
import ViewLayout from '../../assets/svg/ViewLayout.svg'
import { ordersApi } from "../../store/orders/orders";
import { useDispatch, useSelector } from "react-redux";
import OrderList from "./OrderList";






function ViewOrder() {
    const dispatch = useDispatch();
    
    const {loading:loading,result:orders} = useSelector((state) => state.orders)
    const userD = useSelector((state) => state.getUserDetailReducer?.userDetailData?.result)
    const [userDetail, setUserDetails] = useState([]);
    
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    useEffect(() => {
        setUserDetails(userD)
    },[userD])

    useEffect(() => {
        dispatch(ordersApi())
    },[])
    
    return (  

        <>
             <Appbar/>
             <Drawer/>
             <Body>
             
                <Grid container spacing={1}>    
              
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Stack direction="row" alignItems="center">
                        <img src={ViewLayout} />
                        <Typography
                            color={"#05141F"}
                            fontSize={"17px"}
                            fontWeight={400}
                            marginInlineStart={"10px"}
                            fontFamily={"'KiaSignatureRegular' !important"}
                        >View order
                        </Typography>
                    </Stack>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Stack direction="row" alignItems="center">
                        <Typography
                            color={"#05141F"}
                            fontSize={"17px"}
                            fontWeight={400}
                            marginInlineStart={"10px"}
                            fontFamily={"'KiaSignatureRegular' !important"}
                        >
                        {orders === null ? 'No data found' :""}
                        </Typography>
                    </Stack>
                    </Grid>
                    


                    {orders?.map((item) =>{
                            return <OrderList item={item}/> 
                        })
                    }
                    
                    </Grid>
                </Grid>
             </Body>
        </>  
 
    )   
}

export default ViewOrder