import { yupResolver } from "@hookform/resolvers/yup";
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PersonIcon from '@mui/icons-material/Person';
import { Button, Card, FormControl, FormHelperText, IconButton, InputLabel, Modal, OutlinedInput, Stack, Typography } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from "yup";
import { addTranportDetailsApi } from '../../store/orders/addTranportDetails';
import { ordersApi } from "../../store/orders/orders";


const schema = yup.object({
    transport_phone: yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, { message: "Please enter valid number." }).required(),
    transport_name: yup.string().required(),
    transport_vehicle_no: yup.string().required()
}).required()


export default function AddTransportDetails({ open, setOpen, itemId }) {

    const { register, handleSubmit, formState: { errors }, } = useForm({ resolver: yupResolver(schema) })

    const dispatch = useDispatch()

    const handleClose = () => setOpen(false)

    const handleAddTrasportDetails = async (data) => {
        const tranportDetails = await dispatch(addTranportDetailsApi({
            item_id: itemId,
            ...data
        }))
        if (!tranportDetails?.payload?.error) {
            dispatch(ordersApi())
        }
    }


    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <Card className='modal'>
                    <form onSubmit={handleSubmit(handleAddTrasportDetails)}>
                        <Stack spacing={2}>
                            <Stack alignItems='flex-end'><IconButton onClick={handleClose}><HighlightOffIcon /></IconButton></Stack>
                            <Stack alignItems='center'>
                                <Typography variant='h5' fontWeight={600}>Transport Detail</Typography>
                                <Typography variant='p'>Please fill the transport detail and Delivery contact</Typography>
                            </Stack>

                            <Stack alignItems='center' spacing={2}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="outlined-adornment-tranport_number">Transport Number</InputLabel>
                                    <OutlinedInput
                                        {...register("transport_phone")}
                                        label='Transport Number'
                                        id="outlined-adornment-tranport_number"
                                        startAdornment={<EmojiTransportationIcon />}
                                    />
                                    <FormHelperText error>{errors?.transport_phone?.message}</FormHelperText>
                                </FormControl>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="outlined-adornment-contect_name">Delivery Contact Name</InputLabel>
                                    <OutlinedInput
                                        {...register("transport_name")}
                                        label='Delivery Contact Name'
                                        id="outlined-adornment-contect_name"
                                        startAdornment={<PersonIcon />}
                                    />
                                    <FormHelperText error>{errors?.transport_name?.message}</FormHelperText>
                                </FormControl>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="outlined-adornment-viacal_number">Delivery Viacal Number</InputLabel>
                                    <OutlinedInput
                                        {...register("transport_vehicle_no")}
                                        label='Delivery Viacal Number'
                                        id="outlined-adornment-viacal_number"
                                        startAdornment={<DeliveryDiningIcon />}
                                    />
                                    <FormHelperText error>{errors?.transport_vehicle_no?.message}</FormHelperText>
                                </FormControl>
                            </Stack>


                        </Stack>
                        <Stack direction='row' sx={{ my: 2 }} justifyContent='center'>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button variant='contained' type='submit' >Save</Button>
                        </Stack>
                    </form>
                </Card>
            </Modal>
        </>
    )
}

