import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


const ProtectedRoute = (props) => {

    const navigate = useNavigate();

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const checkUserToken = () => {
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken===null || accessToken === 'undefined') {
            setIsLoggedIn(false);
            return navigate('/login');
        }
        setIsLoggedIn(true);
    }

    useEffect(() => {
        checkUserToken();
    }, [isLoggedIn]);


    return (
        <Fragment>{isLoggedIn ? props.children : null}</Fragment>
    );
}
export default ProtectedRoute;