import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"

export const singleOrderApi = createAsyncThunk(
    'auth/single-order',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(`api/v1/supplier/order`,
                { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") }, params: data },

            )
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const singleOrderSlice = createSlice({
    name: 'single-order',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(singleOrderApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(singleOrderApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(singleOrderApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const singleOrderReducer = singleOrderSlice.reducer