import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button, Card, IconButton, Modal, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { verifyAuthOtpSMSApi } from '../../store/auth/verifyAuthOtpSMS';
import { useForm } from 'react-hook-form';
import SnakBar from '../../common/SnakBar';
import { authOtpViaSMSApi } from '../../store/auth/authOtpViaSMS';
import BackdropUI from '../../ui/Backdrop';
import PropTypes from 'prop-types'
import { MdOutlineKeyboardBackspace } from 'react-icons/md'


export default function OTPSMSAuth({ open, setOpen, back }) {

  const { setError, formState: { errors }, } = useForm()

  const authOtpViaSMSSelector = useSelector(state => state?.authOtpViaSMS)
  const { loading } = authOtpViaSMSSelector

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [otp, setOtp] = useState('')
  const [backdrop, setBackDrop] = useState(false)

  const dealorPhone = localStorage.getItem("phone")

  const handleClose = () => {
    setOpen(false)
    setOtp([])
  }

  const handleBack = () => {
    back(true)
    setOpen(false)
    setOtp([])
  }

  const handleVerify = async () => {
    const verifyData = await dispatch(verifyAuthOtpSMSApi({ otp: Number(otp) }))
    if (!verifyData?.payload?.error) {
      localStorage.setItem("access_token", verifyData?.payload?.result?.access_token)
      navigate("/")
    } else {
      setError('optError', { type: 'custom', message: verifyData?.payload?.message })
    }
  }

  const handleResendOtp = async () => {
    const resendData = await dispatch(authOtpViaSMSApi())
    if (resendData?.payload?.error) {
      setError('optError', { type: 'custom', message: resendData?.payload?.message })
    }
  }

  useEffect(() => {
    if (loading) setBackDrop(true)
    else setBackDrop(false)
  }, [backdrop, loading])


  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Card className='modal'>
          <Stack spacing={2}>
            <Stack direction='row' alignContent='center' justifyContent='space-between'>
              <IconButton onClick={handleBack}><MdOutlineKeyboardBackspace /></IconButton>
              <IconButton onClick={handleClose}><HighlightOffIcon /></IconButton>
            </Stack>
            <Stack alignItems='center'>
              <Typography variant='h5' fontWeight={600}>Verify Number</Typography>
              <Typography>We have sent an OTP to your registered number {dealorPhone?.replace(/(?<=\d\d)\d(?=\d{2})/g, "*")}</Typography>
            </Stack>
            <OtpInput
              containerStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              inputStyle={{ width: 50, height: 50 }}
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderSeparator={<span>&nbsp; &nbsp;</span>}
              renderInput={(props) => <input {...props} />}
            />
            {errors?.optError?.message && <SnakBar type='error' error={errors?.optError?.message} />}
            <Button variant='contained' onClick={handleVerify} disabled={otp?.length < 4}>Verify Number</Button>
            <Stack spacing={0.5} justifyContent='center' direction='row' alignItems='center'>
              <Typography>Didn’t receive OTP?</Typography>
              <Typography sx={{ cursor: 'pointer' }} fontWeight={600} onClick={handleResendOtp}>Resend</Typography>
            </Stack>
          </Stack>
        </Card>
      </Modal>

      <BackdropUI open={backdrop} />
    </>
  )
}

OTPSMSAuth.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.any,
  back: PropTypes.bool,
};