import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: "#05141F"
        },
    },
    typography: {
        subtitle2: {
            fontSize: 10,
        },
        body1: {
            fontSize: 15,
        },
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    padding: 20,
                    borderRadius: 20
                }
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    background: 'none',
                    color: '#fff',
                }
            },
            defaultProps: {
                disableGutters: true,
                elevation: 0
            }

        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    zIndex: 1000
                }
            }
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    zIndex: 1
                }
            }
        }


    }
});

    