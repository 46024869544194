import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ModalCss from "../../styles/modal.module.scss";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../toast/toast";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CloseIcon from "@mui/icons-material/Close";
import { getSupplierApi } from "../../store/supplier";
import { addInventorySchema } from "../validationSchemas";
import { addInventoryApi, editInventoryApi, getInventoryApi } from "../../store/inventory/index.js";

const useStyles = makeStyles((theme) => ({
  bgLightGray: {
    backgroundColor: "rgba(213, 222, 229, 1)",
  },
  borderRadius10: {
    borderRadius: "10px",
  },
  p10: {
    padding: "10px",
  },
  p20: {
    padding: "20px",
  },
  buttonsStyle: {
    backgroundColor: "#D7EDFE",
  },
  fullWidth: {
    width: "100%",
  },
  marginBottom20: {
    marginBottom: "20px",
  },
  inputGroup: {
    display: "flex",
    gap: "20px",
    marginBottom: "20px",
  },
  imageUpload: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "2px dashed #ccc",
    padding: "20px",
    borderRadius: "10px",
    cursor: "pointer",
    position: "relative",
  },
  imageUploadText: {
    marginTop: "10px",
    color: "#888",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  previewImage: {
    maxWidth: "100%",
    maxHeight: "200px",
    borderRadius: "10px",
  },
  deleteIcon: {
    position: "absolute",
    top: "10px",
    right: "10px",
    backgroundColor: "#fff",
    borderRadius: "50%",
    cursor: "pointer",
  },
}));

const AddInventory = ({ open, handleClose, editRowData }) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const supplierList = useSelector((state) => state.supplierReducer?.result);
  const categoryList = useSelector((state) => state.categoryReducer?.result);
  const classes = useStyles();
  const inputRef = useRef(null);
  const [inputValues, setInputValues] = useState();
  const [filePreview, setFilePreview] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const handleInputChangeQuantity = (e) => {
    const { name, value } = e.target;
    const validNumber = /^[0-9]*$/;
    if (validNumber.test(value)) {
        setInputValues({
            ...inputValues,
            [name]: value
        });
    } else {
        // Optionally, you can keep the existing value instead of clearing it.
        setInputValues({
            ...inputValues,
            [name]: inputValues[name] || ""
        });
    }
  }
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setInputValues({ ...inputValues, snap: file });
      setFilePreview(URL.createObjectURL(file));
    } else {
      console.error("No file selected");
    }
  };

  const handleDeleteImage = (e) => {
    e.stopPropagation(); // Prevent triggering the parent click event
    setInputValues({ ...inputValues, image: null });
    setFilePreview(null);
    inputRef.current.value = "";
  };

  const handleUploadClick = () => {
    inputRef.current.click();
  };

  const resetForm = () => {
    setInputValues({
      category_id: "",
      supplier_company_id: "",
      kia_specification: "",
      product_description: "",
      quantity: "",
      snap: null,
      supplier_price: "",
      unit_of_measurement: "",
      vendor_price: "",

    })
    setFilePreview(null);
    inputRef.current.value = "";
  };

  const submitInventoryDetails = (e) => {
    e.preventDefault();
     addInventorySchema.validate(inputValues, { abortEarly: false })
            .then(() => {
                if(editRowData?.id){
                  editRequestApi()
                }else{
                  //addRequestAPI();
                }
               
                setErrors({});
            })
            .catch((validationErrors) => {
                const newErrors = {};
                validationErrors.inner.forEach((error) => {
                    newErrors[error.path] = error.message;
                });
                setErrors(newErrors);
            });
  };

  // const addRequestAPI = async () => {
  //   try {
  //       const dataToBeSent = new FormData();
  //       dataToBeSent.append('category_id', inputValues?.category_id);
  //       dataToBeSent.append('snap', inputValues?.snap);
  //       dataToBeSent.append('supplier_company_id', inputValues?.supplier_company_id);
  //       dataToBeSent.append('kia_specification', inputValues?.kia_specification);
  //       dataToBeSent.append('product_description', inputValues?.product_description);
  //       dataToBeSent.append('quantity', inputValues?.quantity);
  //       dataToBeSent.append('supplier_price', 0);
  //       dataToBeSent.append('unit_of_measurement', inputValues?.unit_of_measurement);
  //       dataToBeSent.append('vendor_price', 0);
  //       const response = await dispatch(addInventoryApi(dataToBeSent));
  //       showToast('Inventory added successfully', 1)
  //       dispatch(getInventoryApi())
  //       resetForm();
  //       handleClose();
  //   } catch (error) {
  //       showToast(error, 2);
  //   }
    
  // }

  const editRequestApi = async () => {
    
    try {
      const dataToBeSent = new FormData();
      dataToBeSent.append('product_id', editRowData?.id);
      dataToBeSent.append('category_id', inputValues?.category_id);
      dataToBeSent.append('supplier_company_id', inputValues?.supplier_company_id ? inputValues?.supplier_company_id : editRowData?.supplier_id);
      dataToBeSent.append('kia_specification', inputValues?.kia_specification);
      dataToBeSent.append('product_description', inputValues?.product_description);
      dataToBeSent.append('quantity', inputValues?.quantity);
      dataToBeSent.append('supplier_price', inputValues?.supplier_price);
      dataToBeSent.append('unit_of_measurement', inputValues?.unit_of_measurement);
      dataToBeSent.append('vendor_price', inputValues?.vendor_price);
      if (filePreview) {
        dataToBeSent.append('snap', inputValues?.snap);
      } else {
        dataToBeSent.append('snap', inputValues?.snap || editRowData?.snap);
      }
      const response = await dispatch(editInventoryApi(dataToBeSent));
      if(response?.payload?.status === 'success'){
        showToast(response?.payload?.message, 1)
        dispatch(getInventoryApi())
        resetForm();
        handleClose();
      }else{
        showToast('Failed to edit product', 2)
      }

    } catch (error) {
      showToast(error, 2);
    }
    
}

  const handleCancel = () => {
    resetForm();
    handleClose();
  };

  useEffect(() => {
    setInputValues(editRowData? editRowData : null)
  },[editRowData])

  return (
    <Modal
      open={open}
      onClose={() => {}}
      className={ModalCss?.customModal}
      BackdropProps={{
        style: { pointerEvents: "none" }, // Disable click events on the backdrop
      }}
      
    >
      <Card className={`modal ${ModalCss?.customModal_md}`}>
        <CardHeader
          className="popup-header-rev"
          action={
            <IconButton onClick={handleCancel}>
              <HighlightOffIcon />
            </IconButton>
          }
          title={
            <Box display="flex" justifyContent="center" alignItems="center" width="100%">
              <Typography variant="h6">{editRowData?.id ? "Update" : "Add"} item to inventory</Typography>
            </Box>
          }
        />
        <Divider />
        <CardContent sx={{ overflowY: 'auto', maxHeight: '60vh' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <FormLabel htmlFor="supplier-company-select">Supplier Company</FormLabel>
                <Select
                  disabled
                  id="supplier-company-select"
                  value={inputValues?.supplier_company_id ? inputValues?.supplier_company_id : Number(inputValues?.supplier_id)}
                  onChange={handleInputChange}
                  name="supplier_company_id"
                  sx={{ marginTop: "10px" }}
                >
                  {(Array.isArray(supplierList) ? supplierList : []).map((supplier) => (
                    <MenuItem key={supplier.id} value={supplier.id}>
                      {supplier.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  error={Boolean(errors.supplier_company_id)}
                  sx={{ cursor: 'pointer' }} // Added cursor style
                >
                  {errors?.supplier_company_id}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <FormLabel htmlFor="category-select">Category</FormLabel>
                <Select
                  disabled
                  id="category-select"
                  label="Category"
                  value={inputValues?.category_id ? inputValues?.category_id : Number(inputValues?.category_id)}
                  onChange={handleInputChange}
                  name="category_id"
                  error={Boolean(errors.category_id)}
                  sx={{ marginTop: "10px" }}
                >
                  {categoryList?.map((cate) => (
                    <MenuItem key={cate.id} value={cate.id}>
                      {cate.category}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={Boolean(errors.category_id)}  sx={{ cursor: 'pointer' }} >
                  {errors.category_id}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin="normal">
                <FormLabel htmlFor="kia-specification">Supplier Specification</FormLabel>
                <TextField
                  disabled
                  id="kia-specification"
                  value={inputValues?.kia_specification}
                  onChange={handleInputChange}
                  name="kia_specification"
                  error={Boolean(errors.kia_specification)}
                  helperText={errors.kia_specification}
                  sx={{ marginTop: "10px" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth margin="normal">
                <FormLabel htmlFor="unit-of-measurement">Unit of Measurement</FormLabel>
                <TextField
                  disabled
                  id="unit-of-measurement"
                  name="unit_of_measurement"
                  value={inputValues?.unit_of_measurement}
                  error={Boolean(errors.unit_of_measurement)}
                  helperText={errors.unit_of_measurement}
                  onChange={handleInputChange}
                  sx={{ marginTop: "10px" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth margin="normal">
                <FormLabel htmlFor="quantity">Quantity</FormLabel>
                <TextField
                  id="quantity"
                  name="quantity"
                  value={inputValues?.quantity}
                  error={Boolean(errors.quantity)}
                  helperText={errors.quantity}
                  onChange={handleInputChangeQuantity}
                  sx={{ marginTop: "10px" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth margin="normal">
                <FormLabel htmlFor="supplier-price">Supplier Price</FormLabel>
                <TextField
                  disabled
                  id="supplier-price"
                  name="supplier_price"
                  placeholder="Not Permitted"
                  value={inputValues?.supplier_price}
                  error={Boolean(errors.supplier_price)}
                  helperText={errors.supplier_price}
                  onChange={handleInputChange}
                  sx={{ marginTop: "10px" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth margin="normal">
                <FormLabel htmlFor="vendor-price">Dealor Price</FormLabel>
                <TextField
                  disabled
                  id="vendor-price"
                  name="vendor_price"
                  placeholder="Not Permitted"
                  value={inputValues?.vendor_price}
                  error={Boolean(errors.vendor_price)}
                  helperText={errors.vendor_price}
                  onChange={handleInputChange}
                  sx={{ marginTop: "10px" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin="normal">
                <FormLabel htmlFor="product-description">Product Description</FormLabel>
                <TextField
                  disabled
                  id="product-description"
                  name="product_description"
                  value={inputValues?.product_description}
                  error={Boolean(errors.product_description)}
                  helperText={errors.product_description}
                  onChange={handleInputChange}
                  sx={{ marginTop: "10px" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.imageUpload} onClick={filePreview ? null : handleUploadClick}>
                  <input
                  disabled
                    type="file"
                    ref={inputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                  {filePreview ? (
                    // If filePreview exists, show the file preview with delete option
                    <>
                      <img src={filePreview} alt="Preview" className={classes.previewImage} />
                      <CloseIcon className={classes.deleteIcon} onClick={handleDeleteImage} />
                    </>
                  ) : editRowData?.snap ? (
                    // If no filePreview but editRowData has a snap, show the snap with upload option
                    <>
                    <img src={`${editRowData.snap}`} alt="Snap" className={classes.previewImage} />
                      <FileUploadIcon />
                      <Typography className={classes.imageUploadText}>
                        Drag your image here, or browse
                      </Typography>
                    </>
                  ) : (
                    // If no filePreview and no snap, show upload option
                    <>
                      <FileUploadIcon />
                      <Typography className={classes.imageUploadText}>
                        Drag your image here, or browse
                      </Typography>
                    </>
                  )}
            </Box>
            </Grid>
          </Grid>
        </CardContent>


        <CardActions disableSpacing>
          <Box component={"div"} width={"100%"} marginBlockStart={0.5} className={classes.buttonContainer}>
            <Button
              variant="contained"
              sx={{
                fontSize: "18px",
                fontWeight: "500",
                lineHeight: 1.8,
                boxShadow: "none",
                backgroundColor: "#000 !important",
                p: {
                  lg: "15px 46px",
                  md: "13px 35px",
                  sm: "10px 30px",
                  xs: "10px 30px",
                },
              }}
              className={classes.buttonsStyle}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                fontSize: "18px",
                fontWeight: "500",
                lineHeight: 1.8,
                boxShadow: "none",
                backgroundColor: "#000 !important",
                p: {
                  lg: "15px 46px",
                  md: "13px 35px",
                  sm: "10px 30px",
                  xs: "10px 30px",
                },
              }}
              className={classes.buttonsStyle}
              onClick={submitInventoryDetails}
            >
              {editRowData?.id ? "Update" : "Add"} Inventory
            </Button>
          </Box>
        </CardActions>
        </Card>
      </Modal>
    );
  };

  export default AddInventory;
