import InventoryIcon from '@mui/icons-material/Inventory'
import SearchIcon from '@mui/icons-material/Search'
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Grid, InputAdornment, Paper, Stack, TextField, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Appbar from '../../components/Appbar'
import Body from '../../components/Body'
import Drawer from '../../components/Drawer'
import { ordersApi } from '../../store/orders/orders'
import AddTransportDetails from './AddTransportDetails'
import OrderDetails from './OrderDetails'
import { changeOrderStatusApi } from '../../store/orders/changeOrderStatus'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const productStatusOptions = ['none', 'packed', 'shipped', 'dispatch', 'delivered'];


export default function OrderStatus({ first_name }) {
    const ordersSelector = useSelector(state => state?.orders)
    const { result, loading } = ordersSelector

    const dispatch = useDispatch()

    const [orderDetailsModel, setOrderDetailsModel] = useState(false)
    const [tranportDetails, setTransportetails] = useState(false)
    const [productStatus, setPorductStatus] = useState('');
    const [itemId, setItemId] = useState(null)

    const columns = [
        {
            field: 'order_id',
            headerName: 'Order ID',
            width: 300,
            renderCell: (params) => (
                <Button onClick={handleOrderDetails} sx={{ color: '#2073B0' }}>{params?.row?.order}</Button>
            )
        },
        {
            field: 'dealer',
            headerName: 'Supplier',
            width: 200
        },
        {
            field: 'product',
            headerName: 'Product',
            width: 300,
            renderCell: (params) => (
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography sx={{ color: '#000' }}>{params?.row?.product?.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography sx={{ color: "#000" }}>Product : {params?.row?.product?.name}</Typography>
                        <Typography sx={{ color: "#000" }}>Category : {params?.row?.product?.category_id}</Typography>
                        <Typography sx={{ color: "#000" }}>Description : {params?.row?.product?.description}</Typography>
                    </AccordionDetails>
                </Accordion>
            )
        },
        {
            field: 'quantity',
            headerName: 'Order Quantity',
            width: 200
        },
        {
            field: 'order_date',
            headerName: 'Order Date',
            width: 200
        },
        {
            field: 'EDD',
            headerName: 'EDD',
            width: 200
        },
        {
            field: 'item_status',
            headerName: 'Product Status',
            width: 200,
            renderCell: (params) => (
                <Autocomplete
                    defaultValue={params?.row?.item_status}
                    onChange={(event, newValue) => handleChaneProductStatus(event, newValue, params)}
                    size='small'
                    options={productStatusOptions}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField  {...params} label="In Stock" />}
                />
            )
        },

    ]

    const handleChaneProductStatus = async (event, newValue, params) => {
        setItemId(params?.row?.id)
        setPorductStatus(newValue)
        const orderstatus = await dispatch(changeOrderStatusApi({ item_id: params?.row?.id, item_status: newValue }))
        if (!orderstatus?.payload?.error) {
            dispatch(ordersApi())
        }
        if (newValue == "shipped") setTransportetails(true)

    }

    const handleOrderDetails = () => {
        setOrderDetailsModel(true)
    }

    const handleSearchOrders = (evt) => {
        dispatch(ordersApi({ search: evt.target.value }))
    }

    useEffect(() => {
        dispatch(ordersApi())
    }, [dispatch])


    return (
        <>
            <Appbar />
            <Drawer />
            <Body>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Box className='divider1'></Box>
                    <Typography variant='h6'>{`Welcome ${first_name}`}</Typography>
                </Stack>
                <Grid container alignItems='center'>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Stack direction='row' spacing={1} sx={{ my: 2 }} alignItems='center'>
                            <InventoryIcon fontSize='large' />
                            <Typography>View Order</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <TextField fullWidth size='small' InputProps={{ startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>) }} placeholder='Search by Order ID , Product Name' onChange={handleSearchOrders} />
                    </Grid>
                </Grid>

                <Paper elevation={0}>
                    <DataGrid
                        getRowHeight={() => 'auto'}
                        getRowId={(row) => row.id}
                        rows={result}
                        columns={columns}
                        loading={loading}
                        disableRowSelectionOnClick
                        pagination
                        rowsPerPageOptions={[25, 50, 100]}
                    />
                </Paper>
            </Body>

            <OrderDetails open={orderDetailsModel} setOpen={setOrderDetailsModel} />
            <AddTransportDetails open={tranportDetails} setOpen={setTransportetails} itemId={itemId} />
        </>
    )
}
