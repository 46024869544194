//Date formater for MM/DD/YYYY
export  const convertDateFunction = (date) => {
    const inputDate = new Date(date);
    const formattedDate = `${(inputDate.getMonth() + 1).toString().padStart(2, "0")}/${inputDate.getDate().toString().padStart(2, "0")}/${inputDate.getFullYear()}`;
    return formattedDate;
};


//Date formatter for YYYY-MM-DD
export const convertDateFunctionYMD = (date) => {
    const inputDate = new Date(date);
    const month = inputDate.getMonth() + 1;
    const day = inputDate.getDate();
    const year = inputDate.getFullYear();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
    return formattedDate;
};

//Date formater for DD/MM/YYYY
export const convertToDateFormat = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}


//Year with last two digits : date formater for DD/MM/YY
export const convertToDateFormatYearWithTwoDigits = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year

    return `${day}/${month}/${year}`;
}

export const formatDateString = (dateString) => {
    const date = new Date(dateString);
    
    const options = { 
        day: 'numeric', 
        month: 'long', 
        year: 'numeric' 
    };

    // Creating a custom formatter for day suffix (st, nd, rd, th)
    const daySuffix = (day) => {
        if (day > 3 && day < 21) return 'th';
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };

    const day = date.getDate();
    const dayWithSuffix = `${day}${daySuffix(day)}`;

    const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);
    return formattedDate.replace(date.getDate(), dayWithSuffix);
}