import React from 'react'
import { Box, Card, Grid, Stack, Typography } from '@mui/material'
function DashboardViewGfc() {
  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card sx={{ borderRadius: "8px" }}>
                  
                  <Typography variant="h6" className="fs-17  fw-600">View GFC</Typography>
                  <Grid container spacing={1} sx={{ mt: 1 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">

                      <Box className="d-flex w-100" sx={{ gap: "12px", paddingBottom: "12px" , overflow: "auto" }}>
 

                        <Box style={{ cursor: "pointer" }} className="greencard" >
                          <Box className="d-flex">
                            <Box sx={{ padding: "13.5px 11px" }} className="content d-flex w-50">
  
                              <Box>
                                <Typography variant="h6" className="fs-13 fw-400 link">
                                  Amit KIA
                                </Typography>
                                <Typography variant="h6" className="fs-13 fw-400" sx={{color:"#536878"}}>
                                  Noida
                                </Typography>
                                {/* <Typography variant="h6" className=" fw-600">2S</Typography> */}
                              </Box>
                            </Box>
                            <Box className="side-content d-flex bg-light-green w-50" sx={{paddingInline: "11px !important"}}>
                              <Box className="flex-column m-auto text-center">
                                {/* <Typography className="fs-20">
                                  100%
                                </Typography>
                                <Typography className="fs-12 fw-800">
                                  Completed
                                </Typography> */}
                                <Typography variant="h5" className=" fw-600" sx={{color:"#465A68"}}>2S</Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box style={{ cursor: "pointer" }} className="greencard" >
                          <Box className="d-flex">
                            <Box sx={{ padding: "13.5px 11px" }} className="content d-flex w-50">
  
                              <Box>
                                <Typography variant="h6" className="fs-13 fw-400 link">
                                  Amit KIA
                                </Typography>
                                <Typography variant="h6" className="fs-13 fw-400" sx={{color:"#536878"}}>
                                  Lucknow
                                </Typography>
                                {/* <Typography variant="h6" className=" fw-600">2S</Typography> */}
                              </Box>
                            </Box>
                            <Box className="side-content d-flex bg-light-green w-50" sx={{paddingInline: "11px !important"}}>
                              <Box className="flex-column m-auto text-center">
                                {/* <Typography className="fs-20">
                                  100%
                                </Typography>
                                <Typography className="fs-12 fw-800">
                                  Completed
                                </Typography> */}
                                <Typography variant="h5" className=" fw-600" sx={{color:"#465A68"}}>2S</Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box style={{ cursor: "pointer" }} className="greencard" >
                          <Box className="d-flex">
                            <Box sx={{ padding: "13.5px 11px" }} className="content d-flex w-50">
  
                              <Box>
                                <Typography variant="h6" className="fs-13 fw-400 link">
                                  Amit KIA
                                </Typography>
                                <Typography variant="h6" className="fs-13 fw-400" sx={{color:"#536878"}}>
                                  Pune
                                </Typography>
                                {/* <Typography variant="h6" className=" fw-600">2S</Typography> */}
                              </Box>
                            </Box>
                            <Box className="side-content d-flex bg-light-green w-50" sx={{paddingInline: "11px !important"}}>
                              <Box className="flex-column m-auto text-center">
                                {/* <Typography className="fs-20">
                                  100%
                                </Typography>
                                <Typography className="fs-12 fw-800">
                                  Completed
                                </Typography> */}
                                <Typography variant="h5" className=" fw-600" sx={{color:"#465A68"}}>2S</Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box style={{ cursor: "pointer" }} className="greencard" >
                          <Box className="d-flex">
                            <Box sx={{ padding: "13.5px 11px" }} className="content d-flex w-50">
  
                              <Box>
                                <Typography variant="h6" className="fs-13 fw-400 link">
                                  Amit KIA
                                </Typography>
                                <Typography variant="h6" className="fs-13 fw-400" sx={{color:"#536878"}}>
                                  Noida
                                </Typography>
                                {/* <Typography variant="h6" className=" fw-600">2S</Typography> */}
                              </Box>
                            </Box>
                            <Box className="side-content d-flex bg-light-green w-50" sx={{paddingInline: "11px !important"}}>
                              <Box className="flex-column m-auto text-center">
                                {/* <Typography className="fs-20">
                                  100%
                                </Typography>
                                <Typography className="fs-12 fw-800">
                                  Completed
                                </Typography> */}
                                <Typography variant="h5" className=" fw-600" sx={{color:"#465A68"}}>2S</Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
  )
}

export default DashboardViewGfc