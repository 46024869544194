import { Logout, NotificationsOutlined, SearchOutlined } from '@mui/icons-material';
import { Avatar, Badge, Box, Button, CircularProgress, Divider, IconButton, ListItemIcon, Menu, MenuItem, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutApi } from '../store/auth/logout';
import Style from "../styles/appbar.module.scss";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DefaultProfileImg from "../assets/img/defaultProfileImg.jpg";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { getUserDetailApi } from '../store/user/getUserDetail';
import kiainnocean from '../assets/svg/kia-innocean.svg'
import sitemapIconSvg from '../assets/svg/sitemap-svg.png'


export default function Appbar() {


  const currentSupplierSelector = useSelector(state => state?.currentSupplier)
  const { result } = currentSupplierSelector

  const getUserDetail = useSelector((state) => state?.getUserDetailReducer?.userDetailData?.result);


  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleUserMenu = (event) => setAnchorEl(event.currentTarget);
  const handleCLoseUserMenu = () => setAnchorEl(null);


  const handleLogout = async () => {
    localStorage.clear()
    navigate("/login")
  }

  const handleUserProfile = async () => {
    navigate("/user/profile");
  };

  useEffect(() => {
    dispatch(getUserDetailApi());
  }, [dispatch]);

  return (
    <Box className={Style.open}>
      <Stack
        sx={{ px: 2 }}
        width={"100%"}
        justifyContent="space-between"
        direction="row"
        alignItems="center"
        spacing={1}
      >
        <Typography variant="h6" className={`${Style?.appBarPageName} d-flex`} sx={{lineHeight: "28px",gap:"12px"}}>
          <img src={kiainnocean} />
          Supplier
        </Typography>
        <Stack direction="row" alignItems="center">
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="outlined"
              className={Style?.noUpperCase}
              startIcon={
                <img src={sitemapIconSvg}/>
              }
            >
              Site Map
            </Button>
            <Button
              variant="outlined"
              className={Style?.noUpperCase} style={{ marginLeft: '10px' }}
              startIcon={<HelpOutlineIcon />}
            >
              Help & Support
            </Button>
            <SearchOutlined/>

            <Badge
              badgeContent={0}
              showZero
              className="headerNotificationBadge"
            >
              <NotificationsOutlined/>
            </Badge>
          </Stack>
          <Divider
            className="horizontal-divider"
            sx={{
              background: "rgba(223, 223, 223, 1) !important",
              width: "1px",
              height: "40px",
              marginInline: "10px !important",
            }}
          />
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="center"
            sx={{ cursor: "pointer", marginInlineStart: "0px !important" }}
          >
            <IconButton className={Style?.avatarButton}

            // sx={{ border: "1px solid #BCCBF3", padding: "0px" }}
            >
              <Avatar style={{ height: '35px', width: '35px' }} src={getUserDetail?.profileImage || DefaultProfileImg} />
            </IconButton>
            <Stack spacing={1} onClick={() => setAnchorEl(true)}>
              <Typography
                variant="subtitle1"
                sx={{ fontSize: "12px", fontFamily: "'KiaSignatureRegular' !important", lineHeight: '1.3' }}
              >
                Supplier User
              </Typography>
              <Typography className={Style?.headerUserName}
                variant="body1"
              // sx={{
              //   fontSize: "14px",
              //   fontWeight: 700,
              //   marginTop: "0px !important",fontFamily: "'KiaSignatureBold' !important",lineHeight:'1.3',
              // }}
              >
                {getUserDetail?.first_name} {getUserDetail?.last_name}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      {/* user menu */}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCLoseUserMenu}
        onClick={handleCLoseUserMenu}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{ mt: "45px" }}
      >
        
        <MenuItem onClick={handleUserProfile}>
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" />
          </ListItemIcon>
          My Profile
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  )
}
