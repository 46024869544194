// src/components/ProtectedLayout.js
import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import Appbar from './Appbar';
import Drawer from './Drawer';

const ProtectedLayout = () => {
  const isLoggedIn = useSelector((state) => state?.login?.result?.email);
  //const isLoggedIn = useSelector((state) => state.auth.isLoggedIn); // Adjust this selector to match your state structure

  return (
    <div>
      {isLoggedIn && <><Appbar /><Drawer /></>}
      <Outlet />
    </div>
  );
};

export default ProtectedLayout;
