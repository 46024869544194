import { combineReducers } from "redux";
import { authOtpViaEmailReducer } from "./auth/authOtpViaEmail";
import { authOtpViaSMSReducer } from "./auth/authOtpViaSMS";
import { currentSupplierReducer } from "./auth/currentSupplier";
import { forgetPasswordViaEmailReducer } from "./auth/forgetPasswordViaEmail";
import { forgetPasswordViaSMSReducer } from "./auth/forgetPasswordViaSMS";
import { loginReducer } from "./auth/login";
import { resetPasswordReducer } from "./auth/resetPassword";
import { resetPasswordVerifyOtpReducer } from "./auth/resetPasswordVerifyOtp";
import { updateSupplierReducer } from "./auth/updateSupplier";
import { verifyAuthOtpEmailReducer } from "./auth/verifyAuthOtpEmail";
import { verifyAuthOtpSMSReducer } from "./auth/verifyAuthOtpSMS";
import { changeOrderStatusReducer } from "./orders/changeOrderStatus";
import { ordersReducer } from "./orders/orders";
import { productListReducer } from "./products/productList";
import { updateProductStockReducer } from "./products/updateProductStock";
import { singleOrderReducer } from "./orders/singleOrder";
import { getUserDetailReducer } from "./user/getUserDetail";
import { logoutReducer } from "./auth/logout";
import { categoryReducer } from "./category";
import { inventoryReducer } from "./inventory";
import { supplierReducer } from "./supplier";
import { verifyAuthOtpApi, verifyAuthOtpReducer } from "./auth/verifyAuthOtp";


const rootReducer = combineReducers({
    // auth
    login: loginReducer,
    getUserDetailReducer:getUserDetailReducer,
    currentSupplier: currentSupplierReducer,
    authOtpViaEmail: authOtpViaEmailReducer,
    authOtpViaSMS: authOtpViaSMSReducer,
    verifyAuthOtpEmail: verifyAuthOtpEmailReducer,
    verifyAuthOtpSMS: verifyAuthOtpSMSReducer,
    forgetPasswordViaEmail: forgetPasswordViaEmailReducer,
    forgetPasswordViaSMS: forgetPasswordViaSMSReducer,
    resetPasswordVerifyOtp: resetPasswordVerifyOtpReducer,
    resetPassword: resetPasswordReducer,
    updateSupplier: updateSupplierReducer,

    // current user
    // orders
    orders: ordersReducer,
    changeOrderStatus: changeOrderStatusReducer,
    // products
    productList: productListReducer,
    updateProductStock: updateProductStockReducer,
    singleOrder:singleOrderReducer,
    logoutReducer: logoutReducer,
    categoryReducer:categoryReducer,
    inventoryReducer:inventoryReducer,
    supplierReducer:supplierReducer
})

export default rootReducer