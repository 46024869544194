import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box, Card, IconButton, Modal, Paper, Stack, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { default as React, useEffect } from 'react';
import { useSelector } from 'react-redux';
import NodataImg from "../../assets/img/noData.png";
import { singleOrderApi } from '../../store/orders/singleOrder';


export default function OrderDetails({ open, setOpen }) {

    const singleOrderSelector = useSelector(state => state?.singleOrder)
    const { result, loading } = singleOrderSelector

    useEffect(() => {
        singleOrderApi()  
    },[])

    const columns = [
        {
            field: 'user',
            headerName: 'Supplier',
            width: 150,
            renderCell: (params) => (<Typography>{params?.row?.product?.supplier_id?.first_name}</Typography>)
        },
        {
            field: 'product',
            headerName: 'Product',
            width: 150,
            renderCell: (params) => (<Typography>{params?.row?.product?.category_id}</Typography>)
        },
        {
            field: 'created_at',
            headerName: 'Order Date',
            width: 150,
            renderCell: (params) => (<Typography>{params?.row?.product?.created_at}</Typography>)
        },
        {
            field: 'price',
            headerName: 'Price',
            width: 150,
            renderCell: (params) => (<Typography>{params?.row?.product?.category_id}</Typography>)
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => (<Typography>{params?.row?.status}</Typography>)
        },
    ]

    const handleClose = () => setOpen(false)


    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <Card className='modal'>
                    <Stack spacing={2}>
                        <Stack alignItems='flex-end'><IconButton onClick={handleClose}><HighlightOffIcon /></IconButton></Stack>
                        <Stack direction='row'>
                            <Typography variant='h5'>Order ID:</Typography>
                            <Typography variant='h5' color='#2073B0'>{result?.[0]?.id}</Typography>
                        </Stack>

                        <Stack direction='row' spacing={2}>
                            <Stack direction='row'>
                                <Typography fontWeight={700}>Purchase Order Date:</Typography>
                                <Typography>{result?.[0]?.created_at || "--"}</Typography>
                            </Stack>

                            <Box className='divider3'></Box>

                            <Stack direction='row'>
                                <Typography fontWeight={700}>Payment Date:</Typography>
                                <Typography>{result?.[0]?.payment_date || "--"}</Typography>
                            </Stack>

                            <Box className='divider3'></Box>

                            <Stack direction='row'>
                                <Typography fontWeight={700}>Order Delivered:</Typography>
                                <Typography>{result?.[0]?.delivered_date || "--"}</Typography>
                            </Stack>
                        </Stack>

                        <Paper elevation={0}>
                            <DataGrid
                                sx={{ maxHeight: 500 }}
                                getRowId={(row) => row.id}
                                rows={[]}
                                columns={columns}
                                loading={loading}
                                disableRowSelectionOnClick
                                slots={{
                                    noRowsOverlay: () => <Box className='noData'><img src={NodataImg} alt='no data found image' /></Box>,
                                }}
                                pagination
                                rowsPerPageOptions={[25, 50, 100]}
                            />
                        </Paper>
                    </Stack>
                </Card>
            </Modal>
        </>
    )
}
